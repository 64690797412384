<div class="mat-typography tabsection special-theme">
  <mat-toolbar color="accent">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <h1>
        Tell Us About Your Dream Event!
      </h1>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="info" style=" margin-top:25px; margin-bottom:15px;">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
    style="max-width: 880px; margin-left: auto; margin-right: auto; margin-top:25px; margin-bottom:15px;">
    <mat-expansion-panel-header matRipple style="background-color: #fce4ec;">
      <mat-panel-title style="color: #37474f;">
        Review Our Policy
      </mat-panel-title>
      <mat-panel-description>
        Click To Read More
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p style="padding: 40px 2px 0 2px!important;">
      <mat-icon style="vertical-align:-5px;" color="primary">short_text</mat-icon>&nbsp;<strong>Deposit:</strong>
      Upon booking your design and event
      date, you will be required to put down 50% of your order’s total.
      This will be considered a deposit that secures your date, but will go towards your total. The remaining amount
      of your order’s total will be required on the day of your event before we begin setting up.<br><br>

      <mat-icon style="vertical-align:-5px;" color="primary">short_text</mat-icon>
      &nbsp;<strong>Cancellations:</strong> Upon booking your design and
      event date, you will be required to put down 50% of your order’s
      total. This will be considered a deposit that secures your date, but will go towards your total. The remaining
      amount of your order’s total will be required on the day of your event before we begin setting up.<br><br>

      <mat-icon style="vertical-align:-5px;" color="primary">short_text</mat-icon>&nbsp;<strong>If,</strong> for
      whatever reason, you have to cancel
      our services, our policy is as follows: If you provide a written
      notice of your cancellation no less than 14 days before your booked day, you will be refunded your deposit in
      full; If you cancel our services less than 14 days before your booked day, you will not be refunded your
      deposit.<br><br>

      <mat-icon style="vertical-align:-5px;" color="primary">short_text</mat-icon>&nbsp;<strong>Set-up:</strong> We
      will require at least 1.5-2.5
      hours (depending on size and/or quantity of design(s)) to set-up your
      design. Depending on the design(s), there will be a set timeframe. For any time surpassing that timeframe due to
      delays of the client and/or venue, you will be charged an additional fee. 10-30 minute delay will be an
      additional $20. 30-60 minute delay will be and additional $50. We will not exceed any time after 1 hour of the
      set timeframe.<br><br>
    </p>
  </mat-expansion-panel>
</div>
<div class="eventform">
  <img class="bgImage" src="/assets/balloonred.svg">
  <form [formGroup]="contactForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="form" color="primary">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon style="vertical-align:-5px;" color="primary" style="font-size: 24px;">mail</mat-icon>
        </div>
        <mat-card-title style="color: #37474f; font-weight: lighter; font-family: cursive;">Installation
          Request
          Form</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="form-container">
        <mat-hint style="color: #37474f; font-weight: 250;">
          <mat-icon class="icon-notification animate__swing" style="vertical-align: -3px; font-size: 18px;"
            color="primary">
            notification_important</mat-icon><strong>Friendly Reminder:</strong><br>
          "We do not respond to email, social media, or phone call/text inquiries on the weekend as it's our busiest
          time. We will promptly respond to you in the order received during that following week. Please submit any
          request
          through our website, which can be found by clicking the link on our IG bio.
          You may see us posting photos and/or on our stories, plus please understand that this takes only a few minutes
          and emails and/or phone calls may take up to 30 minutes. We want to be able to give you all our undivided
          attention when discussing your event."
        </mat-hint>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Your Full Name" formControlName="name" required minlength="3">
              <mat-error *ngIf="contactForm.controls['name'].hasError('required')">
                Your full name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Your Phone Number" formControlName="phone" required minlength="8">
              <mat-error *ngIf="contactForm.controls['phone'].hasError('required')">
                Your phone number is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Your Email" formControlName="email" required minlength="4">
              <mat-error *ngIf="contactForm.controls['email'].hasError('email')">
                Valid email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Address of Installation" formControlName="location" required minlength="3">
              <mat-error *ngIf="contactForm.controls['location'].hasError('required')">
                Address of Installation is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput [matDatepicker]="picker" formControlName="picker" required placeholder="Event Date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="contactForm.controls['picker'].hasError('required')">
                Event date is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div> -->
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Date & Time Your Event Begins</mat-label>
              <input matInput placeholder="MM/DD/YYYY at 00:00 AM/PM" formControlName="time" required minlength="8">
              <mat-error *ngIf="contactForm.controls['time'].hasError('required')">
                Date & time your event begins is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Event Time" formControlName="time" required minlength="3">
              <mat-error *ngIf="contactForm.controls['time'].hasError('required')">
                Event time is <strong>required</strong>
              </mat-error>
            </mat-form-field> -->
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Event Type
              </mat-label>
              <mat-select matNativeControl formControlName="type" required>
                <mat-option value="Birthday">Birthday</mat-option>
                <mat-option value="Wedding">Wedding</mat-option>
                <mat-option value="Baby Shower">Baby Shower</mat-option>
                <mat-option value="Corporate Event">Corporate Event</mat-option>
                <mat-option value="Others (if selected, specify below)">Others (if selected, specify below)</mat-option>
              </mat-select>
              <mat-error *ngIf="contactForm.controls['type'].hasError('required')">
                Event type is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Design Choice
              </mat-label>
              <mat-select matNativeControl formControlName="decoration" required>
                <mat-option value="Organic Garland Standard Package">Organic Garland Standard Package</mat-option>
                <mat-option value="Organic Garland Specialty Package">Organic Garland Specialty Package</mat-option>
                <mat-option value="Organic Garland Designer Package">Organic Garland Designer Package</mat-option>
                <mat-option value="Arch Organic Standard Package">Arch Organic Standard Package</mat-option>
                <mat-option value="Arch Organic Grand Package">Arch Organic Grand Package</mat-option>
                <mat-option value="Arch Organic Deluxe Package">Arch Organic Deluxe Package</mat-option>
                <mat-option value="Arch Packed Standard Package">Arch Packed Standard Package</mat-option>
                <mat-option value="Arch Packed Grand Package">Arch Packed Grand Package</mat-option>
                <mat-option value="Arch Packed Deluxe Package">Arch Packed Deluxe Package</mat-option>
                <mat-option value="Digits - 3 ft Single Digit">Digits - 3 ft Single Digit</mat-option>
                <mat-option value="Digits - 6 ft Single Digit">Digits - 6 ft Single Digit</mat-option>
                <mat-option value="Digits - 10 ft Single Digit">Digits - 10 ft Single Digit</mat-option>
                <mat-option value="Digits - 3 ft Double Digit">Digits - 3 ft Double Digit</mat-option>
                <mat-option value="Digits - 6 ft Double Digit">Digits - 6 ft Double Digit</mat-option>
                <mat-option value="Digits - 10 ft Double Digit">Digits - 10 ft Double Digit</mat-option>
                <mat-option value="Columns - 6 ft Classic Package">Columns - 6 ft Classic Package</mat-option>
                <mat-option value="Columns - 6 ft Roman Package">Columns - 6 ft Roman Package</mat-option>
                <mat-option value="Columns - 7 ft Specialty/Holiday Package">Columns - 7 ft Specialty/Holiday Package
                </mat-option>
                <mat-option value="Balloon Bouqutes">Balloon Bouqutes</mat-option>
                <!-- <mat-option value="Sculpted Numbers/Letter">Sculpted Numbers/Letter</mat-option> -->
                <mat-option value="Specialty Design (if selected, describe below)">Specialty Design (if selected,
                  describe
                  below)</mat-option>
              </mat-select>
              <mat-error *ngIf="contactForm.controls['decoration'].hasError('required')">
                Design choice is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Colors Choice(s)</mat-label>
              <input matInput placeholder="Type your color choices" formControlName="colors" required minlength="3">
              <mat-error *ngIf="contactForm.controls['colors'].hasError('required')">
                Color choices are<strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Party Rental Options
              </mat-label>
              <mat-select matNativeControl formControlName="rentals" multiple required>
                <mat-option value="None">None</mat-option>
                <mat-option value="Round White Backdrop - $150">Round White Backdrop - $150</mat-option>
                <mat-option value="Dessert Cart - $150">Dessert Cart - $150</mat-option>
                <mat-option value="Wooden Hexagon Backdrop - $135">Wooden Hexagon Backdrop - $135</mat-option>
                <mat-option value="Champagne Wall - $120">Champagne Wall - $120</mat-option>
                <mat-option value="Gold Diamond Backdrop-$150">Gold Diamond Backdrop - $150</mat-option>
                <mat-option value="Gold and White Pedestals- $50 (set of three)">Gold and White Pedestals - $50 (set
                  of
                  three)</mat-option>
                <mat-option value=" Gold Ring Backdrop - $100"> Gold Ring Backdrop - $100</mat-option>


              </mat-select>
              <mat-error *ngIf="contactForm.controls['rentals'].hasError('required')">
                At least one option is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-hint style="color: #37474f; font-weight: 250;">
          <mat-icon style="vertical-align: -3px; font-size: 18px;" color="primary">
            priority_high</mat-icon>Please check Party Options to view rental items images.
        </mat-hint>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <input matInput placeholder="Other Info" formControlName="info" minlength="3">
            </mat-form-field>
          </div>
        </div>
        <mat-hint style="color: #37474f; font-weight: 250;">
          <mat-icon style="vertical-align: -3px; font-size: 18px;" color="primary">
            notification_important</mat-icon>If Arch is selected, specify whether it is indoor or outdoor.
          If it is outdoor, specify whether it is
          pavement, gravel, grass, asphalt, sand etc.
        </mat-hint>
      </mat-card-content>
      <mat-card-actions>
        <div style="padding: 0 0 15px 15px;">
          <button mat-fab color="warn" type="submit" [disabled]="!contactForm.valid">
            <mat-icon color="primary">send</mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
