import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distinctions',
  templateUrl: './distinctions.component.html',
  styleUrls: ['./distinctions.component.scss']
})
export class DistinctionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
