import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddonComponent } from './addon/addon.component';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  textOne = 'Welcome to K';
  textTwo = 'Dental';
  textThree = 'Arts';
  iconPic = 'assets/kdent-icon.png';
  iconPicStyle = 'margin-bottom: 10px; width: 30px; height: 30px';
  textFour = 'Your Place For A Beautiful Smile';
  // tslint:disable-next-line: variable-name
  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    // this._bottomSheet.open(AddonComponent);
    // setTimeout(() => {
    //   this._bottomSheet.dismiss();
    // }, 2000);
    // window.scroll(0, 0);

  }

}
