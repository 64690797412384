 <div class="mat-typography tabsection special-theme">
  <mat-toolbar color="warn">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <h1>
        404 Error Page
      </h1>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="info" style=" margin-top:25px; margin-bottom:15px;">
</div>
<div class="eventform">
  <form>
    <mat-card class="form" color="primary">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon style="vertical-align:-5px;" color="primary" style="font-size: 24px;">error</mat-icon>
        </div>
        <mat-card-title>
          Sorry the page is not found!
        </mat-card-title>
        <mat-card-subtitle>

        </mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="error">

          <mat-icon style="margin: auto; font-size: 24px;">
            sentiment_very_dissatisfied
          </mat-icon>
          404 Error<br>

          <button mat-raised-button [routerLink]="['/']" style="margin-top: 45px;">Go
            Back Home</button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
