import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contactpage',
  templateUrl: './requestevent.component.html',
  styleUrls: ['./requestevent.component.scss']
})
export class RequestEventComponent implements OnInit {

  panelOpenState = false;

  contactForm: UntypedFormGroup;
  disabledSubmitButton = true;
  messages: Observable<any[]>;

  constructor(private fb: UntypedFormBuilder, public firestore: AngularFirestore) {
    this.messages = firestore.collection('events').valueChanges();
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      location: [null, Validators.required],
      // picker: [null, Validators.required],
      time: [null, Validators.required],
      type: [null, Validators.required],
      decoration: [null, Validators.required],
      colors: [null, Validators.required],
      rentals: [null, Validators.required],
      info: [null],
      date: Date(),

    });
  }

  @HostListener('input') oninput() {
    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  onSubmit() {
    this.firestore.collection('events').add(this.contactForm.value);
    alert('Your event request has been sent.');
    this.contactForm.reset();
    this.disabledSubmitButton = true;
  }
}
