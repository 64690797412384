import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { tap, first } from 'rxjs/operators';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {


  contactForm: UntypedFormGroup;
  disabledSubmitButton = true;
  messages: Observable<any[]>;
  public myImage = this.getRndmFromSet([]);
  getRndmFromSet(set: string | any[]) {
    const rndm = Math.floor(Math.random() * set.length);
    return set[rndm];
  }
  constructor(private fb: UntypedFormBuilder, public firestore: AngularFirestore) {
    this.messages = firestore.collection('messages').valueChanges();
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      message: [null, Validators.required],
      // subject: 'CITA Contact Us',
      date: Date()

    });

    setInterval(() => {
      // tslint:disable-next-line: no-unused-expression
      this.myImage = this.getRndmFromSet(['/assets/tooth-accent.jpg']);
      // this.myImage.style.Transition = 'all 2s';
    }, 100);

  }

  @HostListener('input') oninput() {
    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  onSubmit() {
    this.firestore.collection('messages').add(this.contactForm.value);
    alert('Your message has been sent!');
    this.contactForm.reset();
    this.disabledSubmitButton = true;
  }

}
