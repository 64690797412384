import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './eventgallery.component.html',
  styleUrls: ['./eventgallery.component.scss']
})
export class EventGalleryComponent implements OnInit {

  breakpoint: number;

  scale1 = '1';

  setScale1(value: string): void {
    this.scale1 = value;
  }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 760) ? 1 : 3;
    window.scroll(0, 0);

  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 760) ? 1 : 3;
  }

  constructor() { }

  // Wedding Directory

  // openWeddingSweetheart() {
  //   const w = 1050;
  //   const h = 800;
  //   const left = (screen.width - w) / 2;
  //   const top = (screen.height - h) / 2;
  //   // tslint:disable-next-line: max-line-length
  //   window.open('https://photos.app.goo.gl/Hg7hr67bFY21Sm42A',
  //     'Large Image',
  //     // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: max-line-length
  //     'titlebar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width = ' + w + ', height = ' + h + ', top = ' + top + ', left = ' + left);
  // }

  openGooglePhotos() {
    window.open('https://photos.app.goo.gl/Hg7hr67bFY21Sm42A', 'Image Gallery');
  }

}
