<div class="home">
  <div class="home-image">
    <img src="assets/toothtitle.png" />
  </div>
  <div class="home-intro">
    <div class="welcometext">
      <h1 style="color: #673ab7">Beautiful Smiles For You!</h1>
      <h3 style="padding-top: 10px; color: #673ab7">
        KDental Arts is a modern dental clinic, specialized in advanced
        diagnostics and treatment of dental disorders. We offer comprehensive
        services from many fields of dentistry. In addition to high-end dental
        equipment, all services are provided in a comfortable, luxury
        environment. New patients are welcomed with a complimentary oral health
        consultation.
      </h3>
    </div>
    <h2 style="color: #673ab7">
      <button class="mat-icon-button" mat-icon-button>
        <mat-icon mat-list-icon>keyboard_arrow_right</mat-icon>
      </button>
      General Dentistry
    </h2>
    <h2 style="color: #673ab7">
      <button class="mat-icon-button" mat-icon-button>
        <mat-icon mat-list-icon>keyboard_arrow_right</mat-icon>
      </button>
      Oral Health Evaluation
    </h2>
    <h2 style="color: #673ab7">
      <button class="mat-icon-button" mat-icon-button>
        <mat-icon mat-list-icon>keyboard_arrow_right</mat-icon>
      </button>
      Emergency Care
    </h2>
  </div>
</div>
<div class="button-location">
  <button
    class="mat-flat-button"
    mat-flat-button
    [routerLink]="['/ourservices']"
  >
    Check Our Services
  </button>
</div>
