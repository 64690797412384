<mat-divider></mat-divider>
<div class="dismain">
  <div class="distitle">
    <h1 style="color: #673ab7">Our Distinctions</h1>
  </div>
  <div class="diselem">
    <mat-card class="disone">
      <!-- <i
          class="fas fa-laptop-medical"
          style="font-size: 48px; color: #ff5722"
        ></i>
      </div> -->
      <img
        style="width: 150px !important; height: auto; margin-top: -60px"
        src="assets/laptop-icon.png"
      />

      <h2 style="color: #673ab7">Advanced Dentistry</h2>
    </mat-card>
    <mat-card class="distwo">
      <!-- <i class="fas fa-tooth" style="font-size: 48px; color: #ff5722"></i>
      <br /> -->
      <img
        style="width: 150px !important; height: auto; margin-top: -60px"
        src="assets/tooth-icon.png"
      />
      <h2 style="color: #673ab7">High Quality Equipment</h2>
    </mat-card>
    <mat-card class="disthree">
      <!-- <i class="fas fa-couch" style="font-size: 48px; color: #ff5722"></i>
        <br /> -->
      <img
        style="width: 150px !important; height: auto; margin-top: -60px"
        src="assets/couch-icon.png"
      />
      <h2 style="color: #673ab7">Comfortable Office</h2>
    </mat-card>
    <mat-card class="disfour">
      <!-- <i class='fas fa-user-md' style='font-size:48px;color:#ff5722'></i> -->
      <img
        style="width: 150px !important; height: auto; margin-top: -60px"
        src="assets/staff-icon.png"
      />

      <h2 style="color: #673ab7">Friendly Staff</h2>
    </mat-card>
  </div>
</div>
