import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss']
})
export class AddonComponent {

  // tslint:disable-next-line: variable-name
  constructor(private _bottomSheetRef: MatBottomSheetRef<AddonComponent>) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  closeWindow() {
    this._bottomSheetRef.dismiss();
  }
}
