import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../components/homepage/home/home.component';
import { EventGalleryComponent } from '../components/ourgallery/eventgallery.component';
import { BalloonPricesComponent } from '../components/ourservices/balloonprices.component';
import { RequestEventComponent } from '../components/requestforms/requestevent/requestevent.component';
import { AboutusComponent } from '../components/meetdentist/aboutus.component';
import { Error404Component } from '../components/error404/error404.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'ourgallery', component: EventGalleryComponent },
  { path: 'ourservices', component: BalloonPricesComponent },
  { path: 'requestevent', component: RequestEventComponent },
  { path: 'meetdentist', component: AboutusComponent },
  { path: '**', component: Error404Component }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
