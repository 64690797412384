// CORE
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { AppRoutingModule } from './shared/app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// END CORE

// FIREBASE
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
// END FIREBASE

// COMPONENTS
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BodyComponent } from './components/homepage/body/body.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/homepage/toolbar/toolbar.component';
import { ContactusComponent } from './components/homepage/contactus/contactus.component';
import { TitleimageComponent } from './components/homepage/titleimage/titleimage.component';
import { HomeComponent } from './components/homepage/home/home.component';
import { EventGalleryComponent } from './components/ourgallery/eventgallery.component';
import { BalloonPricesComponent } from './components/ourservices/balloonprices.component';
import { RequestEventComponent } from './components/requestforms/requestevent/requestevent.component';
import { AboutusComponent } from './components/meetdentist/aboutus.component';
import { AddonComponent } from './components/homepage/toolbar/addon/addon.component';
import { MapComponent } from './components/homepage/map/map.component';
import { BackcolorComponent } from './components/homepage/backcolor/backcolor.component';
import { RadiantComponent } from './components/homepage/radiant/radiant.component';
import { DistinctionsComponent } from './components/homepage/distinctions/distinctions.component';
import { Error404Component } from './components/error404/error404.component';

// END COMPONENTS

// MATERIAL
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MaterialModule } from './shared/app-material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

// END MATERIAL

const globalRippleConfig: RippleGlobalOptions = {
  disabled: false,
  animation: {
    enterDuration: 600,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    ToolbarComponent,
    ContactusComponent,
    TitleimageComponent,
    HomeComponent,
    EventGalleryComponent,
    BalloonPricesComponent,
    RequestEventComponent,
    Error404Component,
    AboutusComponent,
    AddonComponent,
    MapComponent,
    BackcolorComponent,
    RadiantComponent,
    DistinctionsComponent

  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
  ],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig
    },
    {
      provide: APP_BASE_HREF, useValue: '/'
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

