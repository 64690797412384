<div class=" tabsection special-theme">
  <mat-toolbar color="warn">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <h1>
        Our Services
      </h1>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<mat-tab-group style="position: relative; z-index: 0;" color="primary" backgroundColor="warn" animationDuration="500ms"
  mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      <span style="color:#212121; font-family: cursive;">Service 1</span>
    </ng-template>
    <div class="eventform">

      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Comming Soon
          </mat-card-title>
          <mat-card-subtitle> Comming Soon
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img mat-card-image src=" " alt="Service1">
        <mat-card-content class="form-container">

          <mat-icon color="accent" style="vertical-align:-5px;">short_text</mat-icon>
          <br>
          <h3>
            Coming soon<br>
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero temporibus deterruisset mel. Has ea dicta
            summo labitur. Eu nullam ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet. Eam ne adhuc
            ullum
            veniam. Et dicta simul fuisset per.</h3>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>

          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a mat-flat-button class="topmenu mat-flat-button" style="text-decoration: none;" href="tel:1-‪704-234-0138">
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>

        </mat-card-actions>
      </mat-card>

    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span style="color:#212121; font-family: cursive;">Service 2</span>
    </ng-template>
    <div class="eventform">

      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title style="font-weight: 300;">Comming Soon
          </mat-card-title>
          <mat-card-subtitle style="font-weight: 300;"> Comming Soon
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img mat-card-image src=" " alt="Service2">
        <mat-card-content class="form-container">

          <mat-icon color="accent" style="vertical-align:-5px;">short_text</mat-icon>
          <br>
          <h3 style="font-weight: 300;">
            Coming soon<br>
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero temporibus deterruisset mel. Has ea dicta
            summo labitur. Eu nullam ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet. Eam ne adhuc
            ullum
            veniam. Et dicta simul fuisset per.</h3>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>

          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a mat-flat-button class="topmenu mat-flat-button" style="text-decoration: none;" href="tel:1-‪704-234-0138">
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>

    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span style="color:#212121; font-family: cursive;">Service 3</span>
    </ng-template>
    <div class="eventform">

      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title style="font-weight: 300;">Comming Soon
          </mat-card-title>
          <mat-card-subtitle style="font-weight: 300;"> Comming Soon
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img mat-card-image src=" " alt="Service3">
        <mat-card-content class="form-container">

          <mat-icon color="accent" style="vertical-align:-5px;">short_text</mat-icon>
          <br>
          <h3 style="font-weight: 300;">
            Coming soon<br>
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero temporibus deterruisset mel. Has ea dicta
            summo labitur. Eu nullam ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet. Eam ne adhuc
            ullum
            veniam. Et dicta simul fuisset per.</h3>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>

          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a mat-flat-button class="topmenu mat-flat-button" style="text-decoration: none;" href="tel:1-‪704-234-0138">
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>

    </div>
  </mat-tab>
