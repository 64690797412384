<div class="tabsection special-theme">
  <mat-toolbar color="warn">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <h1>Our Team</h1>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<mat-tab-group
  style="position: relative; z-index: 0"
  color="primary"
  backgroundColor="warn"
  danimationDuration="500ms"
  mat-align-tabs="center"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        style="
          color: #212121;
          font-family: cursive;
          font-weight: bold;
          font-size: 18px;
        "
        >Dentist</span
      >
    </ng-template>
    <div class="eventform">
      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Your Beautiful Smile Is My Job </mat-card-title>
          <mat-card-subtitle>
            Making A Beautifl Smile Real For You
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img
          mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/yurikoval.jpg?alt=media&token=86c37238-8b60-4b53-b1f5-39dab0d6fe53"
          alt="About Me"
        />
        <mat-card-content class="form-container">
          <h3>
            Yuriy Koval<br />
            Dentist, DDS<br />
            <br />
            <mat-icon color="accent" style="vertical-align: -5px"
              >short_text</mat-icon
            >
            <br />
            Coming soon<br />
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero
            temporibus deterruisset mel. Has ea dicta summo labitur. Eu nullam
            ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet.
            Eam ne adhuc ullum veniam. Et dicta simul fuisset per.
          </h3>
        </mat-card-content>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a
            mat-flat-button
            class="topmenu mat-flat-button"
            style="text-decoration: none"
            href="tel:1-‪704-234-0138"
          >
            &nbsp;&nbsp;&nbsp; Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        style="
          color: #212121;
          font-family: cursive;
          font-weight: bold;
          font-size: 18px;
        "
        >Office Manager</span
      >
    </ng-template>
    <div class="eventform">
      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Your Beautiful Smile Is My Job </mat-card-title>
          <mat-card-subtitle>
            Making A Beautifl Smile Real For You
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img
          mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/lyuba_manager.jpeg?alt=media&token=dc7cc3c0-f257-4c92-98d5-1c4ed7e495bb"
          alt="About Me"
        />
        <mat-card-content class="form-container">
          <h3>
            Lyuba<br />
            Office Manager<br />
            <br />
            <mat-icon color="accent" style="vertical-align: -5px"
              >short_text</mat-icon
            >
            <br />
            Coming soon<br />
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero
            temporibus deterruisset mel. Has ea dicta summo labitur. Eu nullam
            ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet.
            Eam ne adhuc ullum veniam. Et dicta simul fuisset per.
          </h3>
        </mat-card-content>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a
            mat-flat-button
            class="topmenu mat-flat-button"
            style="text-decoration: none"
            href="tel:1-‪704-234-0138"
          >
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        style="
          color: #212121;
          font-family: cursive;
          font-weight: bold;
          font-size: 18px;
        "
        >Administrator</span
      >
    </ng-template>
    <div class="eventform">
      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Your Beautiful Smile Is My Job </mat-card-title>
          <mat-card-subtitle>
            Making A Beautifl Smile Real For You
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img
          mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/daniel_administrator.jpg?alt=media&token=2ddb4477-4924-4d7e-9732-9e0c0e26a0f5"
          alt="About Me"
        />
        <mat-card-content class="form-container">
          <h3>
            Daniel<br />
            Patient Care Coordinator & Administrator<br />
            <br />
            <mat-icon color="accent" style="vertical-align: -5px"
              >short_text</mat-icon
            >
            <br />
            Coming soon<br />
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero
            temporibus deterruisset mel. Has ea dicta summo labitur. Eu nullam
            ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet.
            Eam ne adhuc ullum veniam. Et dicta simul fuisset per.
          </h3>
        </mat-card-content>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a
            mat-flat-button
            class="topmenu mat-flat-button"
            style="text-decoration: none"
            href="tel:1-‪704-234-0138"
          >
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        style="
          color: #212121;
          font-family: cursive;
          font-weight: bold;
          font-size: 18px;
        "
        >Hygienist</span
      >
    </ng-template>
    <div class="eventform">
      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Your Beautiful Smile Is Our Job </mat-card-title>
          <mat-card-subtitle>
            Making A Beautifl Smile Real For You
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img
          mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/alla_hygienist.jpeg?alt=media&token=2b55d0b2-91c6-4381-ad90-c37e8c8349a5"
          alt="About Me"
        />
        <mat-card-content class="form-container">
          <h3>
            Alla<br />
            Hygienist<br />
            <br />
            <mat-icon color="accent" style="vertical-align: -5px"
              >short_text</mat-icon
            >
            <br />
            Coming soon<br />
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero
            temporibus deterruisset mel. Has ea dicta summo labitur. Eu nullam
            ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet.
            Eam ne adhuc ullum veniam. Et dicta simul fuisset per.
          </h3>
        </mat-card-content>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a
            mat-flat-button
            class="topmenu mat-flat-button"
            style="text-decoration: none"
            href="tel:1-‪704-234-0138"
          >
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        style="
          color: #212121;
          font-family: cursive;
          font-weight: bold;
          font-size: 18px;
        "
        >Dentist's Assistant</span
      >
    </ng-template>
    <div class="eventform">
      <mat-card class="form" color="primary">
        <mat-card-header>
          <!-- <div mat-card-avatar class="header-image"></div> -->
          <mat-card-title>Your Beautiful Smile Is Our Job </mat-card-title>
          <mat-card-subtitle>
            Making A Beautifl Smile Real For You
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <img
          mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/martha_assistant.jpg?alt=media&token=0fbcbdab-32f8-451c-bbac-b54e32f34d3a"
          alt="About Me"
        />
        <mat-card-content class="form-container">
          <h3>
            Martha<br />
            Dentist's Assistant<br />
            <br />
            <mat-icon color="accent" style="vertical-align: -5px"
              >short_text</mat-icon
            >
            <br />
            Coming soon<br />
            Lorem ipsum dolor sit amet, et pri legimus reprehendunt, cu vero
            temporibus deterruisset mel. Has ea dicta summo labitur. Eu nullam
            ocurreret sed, quas mutat omnium ius ei, sit in reque porro debet.
            Eam ne adhuc ullum veniam. Et dicta simul fuisset per.
          </h3>
        </mat-card-content>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <!-- <button [routerLink]="['/requestevent']">Make An Appointment</button> -->
          <a
            mat-flat-button
            class="topmenu mat-flat-button"
            style="text-decoration: none"
            href="tel:1-‪704-234-0138"
          >
            &nbsp;&nbsp;&nbsp;Call For An Appointment
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-tab>
</mat-tab-group>
