<footer class="home-footer">
  <section id="home-footer-top">
    <div class="home-container">
      <div class="footer-box-one">
        <h2 class="footer-box-title">Thank You</h2>
        <p style="font-size: 16px;">
          Thank you for visiting our website. To learn more about KDenatl Arts, you may contact us via phone. You are
          welcome to visit our office and meet us in person!
        </p>
        <div class="socials">
          <a mat-icon-button href="">
            <mat-icon><i class='fab fa-facebook-f'></i>
            </mat-icon>
          </a>
          <a mat-icon-button href="">
            <mat-icon><i class='fab fa-instagram'></i>
            </mat-icon>
          </a>
          <a mat-icon-button href="">
            <mat-icon><i class='fab fa-pinterest-p'></i>
            </mat-icon>
          </a>
        </div>
      </div>
      <div class="footer-box-two">
        <h2 class="footer-box-title">Contact Us</h2>
        <div>
          <h3>
            <a mat-icon-button href="tel:1-‪704-234-0138">
              <mat-icon>call</mat-icon>
            </a>
            Call Us
          </h3>
          <!-- <h3>
            <a mat-icon-button href="mailto:kovdent@gmail.com">
              <mat-icon>
                email</mat-icon>
            </a>
            Send Email
          </h3> -->
          <h3>
            <a mat-icon-button
              href="https://www.google.com/maps/place/1250+Mann+Dr+Suite+100,+Matthews,+NC+28105/@35.123094,-80.718061,16z/data=!4m5!3m4!1s0x885423ff721f65a3:0xd17c3441aed01f7e!8m2!3d35.123094!4d-80.718061?hl=en">
              <mat-icon>location_on</mat-icon>
            </a>

            Visit Us
          </h3>
        </div>
      </div>
      <div class="footer-box-three">
        <h2 class="footer-box-title"> <button style="background-color: #512da8 ;" class="mat-icon-button"
            mat-icon-button>
            <mat-icon>
              copyright</mat-icon>
          </button>KDental Arts</h2>

        <div>
          <h3>
            <a mat-icon-button
              href="https://drive.google.com/file/d/1HFrFVBIPKrFkvq10BB6dIfrHQgPoofb3/view?usp=sharing">
              <mat-icon>priority_high</mat-icon>
            </a>
            KDenatl Arts Policy
          </h3>
        </div>
      </div>
    </div>
  </section>
</footer>
