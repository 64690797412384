<div class=" tabsection">
  <img class="bgImage" [src]="myImage">
  <form [formGroup]="contactForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="form">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon color="accent">mail</mat-icon>
        </div>
        <mat-card-title class="mat-card-title ">Contact Us</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content style="margin-top: 20px;">
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Your Name</mat-label>
              <input placeholder="Your Name" matInput formControlName="name" required minlength="3">
              <mat-error *ngIf="contactForm.controls['name'].hasError('required')" color="accent">
                Your full name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Your Email</mat-label>

              <input matInput placeholder="Your Email" formControlName="email" required minlength="4">
              <mat-error *ngIf="contactForm.controls['email'].hasError('email')" color="accent">
                Valid email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="form-container" color="primary">
              <mat-label>Message</mat-label>

              <textarea matInput placeholder="Message" formControlName="message" required minlength="4"></textarea>
              <mat-error *ngIf="contactForm.controls['message'].hasError('required')" color="accent">
                Message is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div style="padding: 0 0 15px 15px;">
          <button mat-fab color="primary" type="submit" [disabled]="!contactForm.valid">
            <mat-icon color="accent">send</mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
