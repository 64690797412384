<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer position="end" class="sidenav" fixedInViewport="true">
    <mat-toolbar id="top" class="mat-toolbar">
      <div class="home-logo mr-1">
        <button mat-icon-button (click)="drawer.toggle()"
          style="cursor: pointer; background-color: white; margin-bottom: 15px;">
          <mat-icon color="accent">clear
          </mat-icon>
        </button>
        <img style=" height: 50px; margin-left: 20%; margin-top: 10px;
           width:auto;" src='/assets/logo_purple.png'>

      </div>
    </mat-toolbar>
    <mat-nav-list>
      <mat-list>
        <a class="mat-list-item" mat-list-item matRipple style="padding-left:3%; margin-top:20%;" [routerLink]="['/']"
          (click)="drawer.toggle()">Home</a>
        <a class="mat-list-item" mat-list-item matRipple style="padding-left:3%;" [routerLink]="['/meetdentist']"
          (click)="drawer.toggle()">Meet Our Team</a>
        <a class="mat-list-item" mat-list-item matRipple style="padding-left:3%;" [routerLink]="['/ourservices']"
          (click)="drawer.toggle()">Our
          Services</a>

        <a class="mat-list-item" mat-list-item matRipple style="padding-left:2%;" [routerLink]="['/ourgallery']"
          (click)="drawer.toggle()">Our
          Gallery</a>

      </mat-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>


      <a [routerLink]="['/']"><img
          style="text-decoration: none; height: 55px; width:auto; padding-left:20px; padding-top:9px; padding-right: 15px; padding-bottom: 8px;"
          src='/assets/logo_purple.png'></a>

      <section class="stage">
        <figure class="ball bubble"></figure>
      </section>

      <span class="spacer"></span>
      <button mat-flat-button class="mat-flat-button" style="margin-left:0px;" [routerLink]="['/']">Home</button>
      <button mat-flat-button class="mat-flat-button" style="margin-left:6px;" [routerLink]="['/meetdentist']">Meet Our
        Team</button>
      <button mat-flat-button class="mat-flat-button" style="margin-left:6px;" [routerLink]="['/ourservices']">Our
        Services</button>


      <button mat-flat-button class="mat-flat-button" style="margin-left:6px;" [routerLink]="['/ourgallery']">Our
        Gallery</button>


      <a class="menu mat-icon-button" mat-icon-button href="tel:1-‪704-234-0138‬" style="margin-right: 20px;">
        <mat-icon color="accent">call</mat-icon>
      </a>
      <button class="menu mat-icon-button" type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle()" style="cursor: pointer; background-color: white;">

        <mat-icon aria-label="Side nav toggle icon" color="accent">menu</mat-icon>

      </button>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
