import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-balloons',
  templateUrl: './balloonprices.component.html',
  styleUrls: ['./balloonprices.component.scss']
})
export class BalloonPricesComponent implements OnInit {

  // breakpoint: number;
  // constructor() { }

  ngOnInit() {

    window.scroll(0, 0);

    // this.breakpoint = (window.innerWidth <= 760) ? 1 : 1;
  }
  // onResize(event) {
  //   this.breakpoint = (event.target.innerWidth <= 760) ? 1 : 1;
  // }


}
