<button mat-icon-button (click)="closeWindow()">
  <mat-icon color="primary">clear</mat-icon>
</button>
<mat-card>
  <div class="mat-typography bottomsheet">
    <h2 style="font-weight: lighter; padding: -25px -10px 15px -10px;">
      <mat-icon color="accent" style="vertical-align: -6px;">announcement</mat-icon>
      Thank you for visiting KDental Arts! Check what we have for you!
    </h2>
    <mat-card-actions align="left">
      <!-- <button mat-raised-button [routerLink]="['/juniorprograms']" (click)="closeWindow()"
        matTooltip="Olde Providence Racquet Club">OPRC Location
      </button>
      <button style="margin-left: 8px;" mat-raised-button [routerLink]="['/cfbpjuniorprograms']" (click)="closeWindow()"
        matTooltip="Colonel Francis Beatty Park">Read More
      </button> -->
    </mat-card-actions>
  </div>
</mat-card>
