<mat-toolbar class="mat-typography">
  <mat-toolbar-row>
    <span class="spacer"></span>
    <h1 class="one">
      {{textOne}}<img [style]="iconPicStyle" [src]="iconPic">{{textTwo}}</h1>
    <h2 class="three">{{textThree}}
    </h2>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span class="spacer"></span>
    <p class="four">{{textFour}}</p>
  </mat-toolbar-row>
  <mat-toolbar-row> </mat-toolbar-row>
</mat-toolbar>
