import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radiant',
  templateUrl: './radiant.component.html',
  styleUrls: ['./radiant.component.scss']
})
export class RadiantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
