<!-- <img class="logoimg" src='/assets/logo-transp.png'> -->

<div
  id="carousel-captions"
  class="carousel slide carousel-fade"
  data-ride="carousel"
  data-interval="4500"
  data-touch="true"
  data-pause=" false"
>
  <ol class="carousel-indicators">
    <li data-target="#carousel-captions" data-slide-to="0" class="active"></li>
    <li data-target="#carousel-captions" data-slide-to="1"></li>
    <li data-target="#carousel-captions" data-slide-to="2"></li>
    <li data-target="#carousel-captions" data-slide-to="3"></li>
    <li data-target="#carousel-captions" data-slide-to="4"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />
      <div
        class="carousel-caption animate__zoomInLeft"
        [routerLink]="['/ourgallery']"
      >
        <h3>View Our Gallery</h3>
        <p>KDental Arts</p>
      </div>
    </div>

    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call Us</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>
          <a mat-icon-button href="tel:1-‪704-234-0138">
            <mat-icon>call</mat-icon>
          </a>
          Call Us
        </h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>
          <a mat-icon-button href="tel:1-‪704-234-0138">
            <mat-icon>call</mat-icon>
          </a>
          Book An Appointment
        </h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call Us</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call Us</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Email Us</h3>
        <p>KDental Arts!</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call To Learn More</h3>
        <p>KDental Arts!</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Schedule Via Phone Call</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Schedule Via Email</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Book An Appointment</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>We Are Here For You</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Email Us With Questions</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call With Questions</h3>
        <p>KDental Arts</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/title-page.png" class="d-block w-100" alt="" />

      <div class="carousel-caption animate__zoomInLeft">
        <h3>Call Us</h3>
        <p>KDental Arts</p>
      </div>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#carousel-captions"
    role="button"
    data-slide="prev"
  >
    <svg
      width="4em"
      height="4em"
      viewBox="0 0 16 16"
      class="bi bi-chevron-left"
      fill="#ff5722"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  </a>
  <a
    class="carousel-control-next"
    href="#carousel-captions"
    role="button"
    data-slide="next"
  >
    <svg
      width="4em"
      height="4em"
      viewBox="0 0 16 16"
      class="bi bi-chevron-right"
      fill="#ff5722"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  </a>
</div>
