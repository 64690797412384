<div class="tabsection special-theme">
  <mat-toolbar>
    <mat-toolbar-row>
      <span class="spacer"></span>
      <h1>
        Welcome To Our Clinic
      </h1>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-tab-group backgroundColor="warn" animationDuration="600" mat-align-tabs="center" color="primary">
    <mat-tab>
      <ng-template mat-tab-label>
        <span style="font-family: cursive; color:#212121 !important;">View Our Gallery</span>
      </ng-template>
      <mat-grid-list [cols]="breakpoint" rowHeight="4:3" (window:resize)="onResize($event)" gutterSize="5px">

        <!-- <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice0.jpg?alt=media&token=c5aa7766-690b-4128-b949-7f0ae4ffcfe4');">
          <mat-grid-tile-header> </mat-grid-tile-header>
        </mat-grid-tile> -->
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice1.jpg?alt=media&token=6f03ab80-f4ea-46e7-8453-54d7048665cf');">
          <mat-grid-tile-footer></mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url(' https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice2.JPG?alt=media&token=3e15afdd-6058-4834-8a92-eadce4a8d189');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice3.JPG?alt=media&token=a6882d42-4f21-4ce1-abdb-88fd93e352fc');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice4.jpg?alt=media&token=3e02a0a7-d3b9-4c6f-bdda-60353f7caaca');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>

        </mat-grid-tile>

        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice5.jpg?alt=media&token=03685a96-146b-4327-b430-d33278c07112');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>

        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice6.JPG?alt=media&token=20c42180-868f-4a2d-8c28-e75e6ee29c33');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>

        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice7.jpg?alt=media&token=d6294bab-209a-4a78-a4d6-f4d29fba92b3');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>

        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice8.jpg?alt=media&token=750595ba-9caf-4bca-b263-105c8b1b0166');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice9.jpg?alt=media&token=32cb5e7b-4640-4dd8-81f1-5dec0ee64191');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice10.JPG?alt=media&token=2bd0a26d-b7fe-4ee2-b8cd-1ec81357e16b');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url('https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice11.JPG?alt=media&token=a49c9b41-59aa-4783-ac70-3a7755251833');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          style="background-image: url(' https://firebasestorage.googleapis.com/v0/b/k-dentalarts.appspot.com/o/dentaloffice12.jpg?alt=media&token=71ff0d2c-8f58-4a4c-86e6-7549ceea25ff');">
          <mat-grid-tile-footer> </mat-grid-tile-footer>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

  </mat-tab-group>
</div>
